import * as React from "react"
import { Link, graphql } from "gatsby"
import { Container, Col, Row, Badge, Card } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

function BlogIndex({ data, location }) {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allNotion.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="The Trialfire Blog" />
        <p>No blog posts found.</p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="The Trialfire Blog" />
      <Container>
        <Row>
          {posts.map(post => {
            if (post.properties.Featured.value === true) {
              return (
                <Col sm={12} style={{ marginBottom: "2rem" }}>
                  <article
                    className="post-list-item h-100"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <Card
                      key={post.properties.slug.value}
                      style={{
                        borderRadius: "0",
                        border: "0",
                        height: "100%",
                        backgroundImage: `url(${post.childMarkdownRemark.localImage[0].publicURL})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                      }}
                    >
                      <Card.Body
                        style={{
                          padding: "0",
                          background:
                            "linear-gradient(357deg, black, #00000069)",
                        }}
                      >
                        <Row>
                          <Col style={{ padding: "5% 5%" }}>
                            <span>
                              <Badge
                                className={
                                  "--nc-bg-" +
                                  post.properties.Category.value.color
                                }
                                text="light"
                                style={{ fontFamily: "var(--font-heading)" }}
                              >
                                {post.properties.Category.value.name}
                              </Badge>

                              <Link
                                to={post.properties.slug.value}
                                itemProp="url"
                              >
                                <h1
                                  style={{
                                    fontSize: "var(--fontSize-7)",
                                    color: "#fff",
                                    maxWidth: "42rem",
                                  }}
                                  itemProp="headline"
                                >
                                  {post.title}
                                </h1>
                              </Link>

                              <p
                                itemProp="excerpt"
                                style={{
                                  fontFamily: "var(--font-heading)",
                                  fontSize: "var(--fontSize-2)",
                                  lineHeight: "var(--lineHeight-1)",
                                  color: "#fff",
                                  maxWidth: "32rem",
                                }}
                              >
                                {post.properties.excerpt.value}
                              </p>
                            </span>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </article>
                </Col>
              )
            }
          })}
        </Row>

       

        <Row>
          {posts.map(post => {
            if (post.properties.Featured.value !== true) {
              return (
                <Col md={12} lg={6} style={{ marginBottom: "1rem" }}>
                  <article
                    className="post-list-item h-100"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <Card
                      key={post.properties.slug.value}
                      style={{
                        borderRadius: "0",
                        border: "0",
                        height: "100%",
                        backgroundImage: `url(${post.childMarkdownRemark.localImage[0].publicURL})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                      }}
                    >
                      <Card.Body
                        style={{
                          padding: "0",
                          background:
                            "linear-gradient(357deg, black, #00000069)",
                        }}
                      >
                        <Row>
                          <Col style={{ padding: "5% 10%" }}>
                            <span>
                              <Badge
                                className={
                                  "--nc-bg-" +
                                  post.properties.Category.value.color
                                }
                                text="light"
                                style={{ fontFamily: "var(--font-heading)" }}
                              >
                                {post.properties.Category.value.name}
                              </Badge>

                              <Link
                                to={post.properties.slug.value}
                                itemProp="url"
                              >
                                <h1
                                  style={{
                                    fontSize: "var(--fontSize-7)",
                                    color: "#fff",
                                    maxWidth: "42rem",
                                    paddingTop: "5rem",
                                  }}
                                  itemProp="headline"
                                >
                                  {post.title}
                                </h1>
                              </Link>

                              <p
                                itemProp="excerpt"
                                style={{
                                  fontFamily: "var(--font-heading)",
                                  fontSize: "var(--fontSize-2)",
                                  lineHeight: "var(--lineHeight-1)",
                                  color: "#fff",
                                  maxWidth: "32rem",
                                }}
                              >
                                {post.properties.excerpt.value}
                              </p>
                            </span>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </article>
                </Col>
              )
            }
          })}
        </Row>
        
      </Container>
      
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allNotion(
      filter: {properties: {Status: {value: {name: {eq: "Published"}}}}}
      sort: {order: DESC, fields: properties___Publish_On___value___start}
    ) {
      nodes {
        id
        title
        properties {
          Featured {
            value
          }
          excerpt {
            value
          }
          Tags {
            value {
              name
            }
          }
          Category {
            value {
              name
              color
            }
          }
          slug {
            value
          }
        }
        childMarkdownRemark {
          localImage {
            publicURL
          }
        }
      }
    }
  }
`
